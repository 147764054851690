<template>
  <step-lesson-layout :title="$t('module1.part4.lesson2.title')" :title-annotation="$t('module1.part4.lesson2.titleAnnotation')">
    <template v-slot:content>
      <p class="separator"></p>
      <!-- BLOCK1 -->
      <div class="app-text-block">
        <p class="title">{{ $t('module1.part4.lesson2.block1.title') }}</p>
        <p class="paragraphe" v-html="$t('module1.part4.lesson2.block1.annotation')"></p>
      </div>

      <div class="block1-desktop">
        <div class="image-wrapper">
          <img :src="require('@/assets/module1/part4/illu-cumac-def.svg')" :alt="$t('global.imageAlts.kwhcumacExplanation')">
        </div>
        <div class="app-block">
          <p class="app-block-title">
            {{ $t('module1.part4.lesson2.block1.blockTitle') }}
          </p>
          <div class="app-block-content paragraphe" v-html="$t('module1.part4.lesson2.block1.blockContent')"></div>
        </div>
      </div>

      <p class="separator"></p>
      <!-- BLOCK2 -->
      <div class="app-text-block">
        <p class="title">{{ $t('module1.part4.lesson2.block2.title') }}</p>
        <p class="paragraphe" v-html="$t('module1.part4.lesson2.block2.annotation')"></p>
      </div>
      <div class="block2-desktop">
        <div class="app-block">
          <p class="app-block-title">
            {{ $t('module1.part4.lesson2.block2.blockTitle') }}
          </p>
          <div class="app-block-content calcul-cee-block">
            <div class="image-wrapper">
              <img :src="require('@/assets/module1/part4/illu-cee-calcul-montant.svg')" :alt="$t('global.imageAlts.calculCee')">
            </div>
            <div class="training-warning">
              <img :src="require('@/assets/icons/warning.svg')" :alt="$t('global.imageAlts.warning')">
              <p class="paragraphe">{{ $t('module1.part4.lesson2.block2.blockContent') }}</p>
            </div>
            <p class="separator"></p>
            <div class="example">
              <p class="label">{{ $t('module1.part4.lesson2.block2.operation.title') }}</p>
              <p class="operation">
                <span class="primary">{{ $t('module1.part4.lesson2.block2.operation.left') }}</span>
                <span>x</span>
                <span>{{ $t('module1.part4.lesson2.block2.operation.right') }}</span>
                <span>=</span>
                <span class="secondary">{{ $t('module1.part4.lesson2.block2.operation.result') }}</span>
              </p>
              <p class="annotation">
                {{ $t('module1.part4.lesson2.block2.operation.annotation') }}
              </p>
            </div>
          </div>
        </div>

        <!-- GAME BLOCK -->
        <div class="training-question-block">
          <p class="training-question-title">{{ $t('training.global.train') }}</p>
          <quizz-activity :questions="ceeQuestions"
            :is-input="true"
            :show-pagination="true"
            :is-multiple="false"
            :input-unit="$t('module1.part4.lesson2.gameBlock.unitCumac')"
            :input-label="$t('module1.part4.lesson2.gameBlock.answerLabel')"
          >
            <template v-slot:question="{ label, name, content }">
              <div class="cee-question">
                <p class="training-question-label">{{ label }}</p>
                <div class="tips">
                  <div class="tip" v-for="tip in content.tips" :key="tip.name">
                    <div class="image-wrapper">
                      <img class="icon" :src="tip.icon" :alt="tip.name">
                    </div>
                    <p class="tip-label" v-html="tip.label"></p>
                  </div>
                </div>
                <div class="modal-wrapper" v-if="content.modalImage">
                  <app-modal theme="training">
                    <template v-slot:modal-button>
                      <app-button-layout :small="true" color="alternate-secondary">{{ $t('module1.part4.lesson2.gameBlock.cumacModal') }}</app-button-layout>
                    </template>
                    <template v-slot:content>
                      <img :src="content.modalImage" :alt="name" :style="{ width: '100%' }">
                    </template>
                  </app-modal>
                </div>
              </div>
            </template>
            <template v-slot:input-answer-wrapper="{ content, isRight, rightAnswer }">
              <div class="result-wrapper">
                <div class="result-label-wrapper" :class="{ right: isRight }">
                  {{ isRight ? $t('module1.part4.lesson2.gameBlock.good') : $t('module1.part4.lesson2.gameBlock.bad') }}
                </div>
                <div class="result">
                  <p class="title">{{ $t('module1.part4.lesson2.gameBlock.rightAnswer') }}</p>
                  <p class="right-answer">
                    {{ rightAnswer.toLocaleString() }} {{ content.resultUnit }}
                  </p>
                  <p class="result-annotation" v-if="content.resultAnnotation">
                    {{ content.resultAnnotation }}
                  </p>
                </div>
              </div>
            </template>
          </quizz-activity>
        </div>
      </div>

      <!-- RESUME -->
      <div class="button-wrapper">
        <app-button-layout @click="$emit('next-tab')">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import QuizzActivity from '@/components/training/QuizzActivity'
import AppModal from '@/components/layout/AppModal'

export default {
  name: 'Module1Part4Lesson2',
  components: { AppModal, QuizzActivity, AppButtonLayout, StepLessonLayout },
  emits: ['next-tab'],
  data () {
    return {
      ceeQuestions: [
        {
          name: 'question1',
          label: this.$t('module1.part4.lesson2.gameBlock.question1.label'),
          content: {
            tips: [
              {
                name: 'tip1',
                label: this.$t('module1.part4.lesson2.gameBlock.question1.tip1'),
                icon: require('@/assets/module1/part4/illu-tip-menuiserie.svg')
              },
              {
                name: 'tip3',
                label: this.$t('module1.part4.lesson2.gameBlock.question1.tip3'),
                icon: require('@/assets/module1/part4/illu-tip-location.svg')
              }
            ],
            modalImage: require('@/assets/module1/part4/image-fiche-menuiserie-modal.png'),
            resultUnit: this.$t('module1.part4.lesson2.gameBlock.unitCumac')
          },
          rightAnswer: 42000
        },
        {
          name: 'question2',
          label: this.$t('module1.part4.lesson2.gameBlock.question2.label'),
          content: {
            tips: [
              {
                name: 'tip1',
                label: this.$t('module1.part4.lesson2.gameBlock.question2.tip1'),
                icon: require('@/assets/module1/part4/illu-tip-economy.svg')
              },
              {
                name: 'tip2',
                label: this.$t('module1.part4.lesson2.gameBlock.question2.tip2'),
                icon: require('@/assets/module1/part4/illu-tip-money.svg')
              }
            ],
            resultUnit: this.$t('module1.part4.lesson2.gameBlock.unitEuro'),
            resultAnnotation: this.$t('module1.part4.lesson2.gameBlock.question2.resultAnnotation')
          },
          rightAnswer: 210
        },
        {
          name: 'question3',
          label: this.$t('module1.part4.lesson2.gameBlock.question3.label'),
          content: {
            tips: [
              {
                name: 'tip1',
                label: this.$t('module1.part4.lesson2.gameBlock.question3.tip1'),
                icon: require('@/assets/module1/part4/illu-tip-toit-terrasse.svg')
              },
              {
                name: 'tip2',
                label: this.$t('module1.part4.lesson2.gameBlock.question3.tip2'),
                icon: require('@/assets/module1/part4/illu-tip-location.svg')
              },
              {
                name: 'tip3',
                label: this.$t('module1.part4.lesson2.gameBlock.question3.tip3'),
                icon: require('@/assets/module1/part4/illu-tip-chauffage.svg')
              }
            ],
            resultUnit: this.$t('module1.part4.lesson2.gameBlock.unitCumac'),
            modalImage: require('@/assets/module1/part4/image-fiche-toiture-terrasse-modal.png')
          },
          rightAnswer: 18200000
        },
        {
          name: 'question4',
          label: this.$t('module1.part4.lesson2.gameBlock.question4.label'),
          content: {
            tips: [
              {
                name: 'tip1',
                label: this.$t('module1.part4.lesson2.gameBlock.question4.tip1'),
                icon: require('@/assets/module1/part4/illu-tip-economy.svg')
              },
              {
                name: 'tip2',
                label: this.$t('module1.part4.lesson2.gameBlock.question4.tip2'),
                icon: require('@/assets/module1/part4/illu-tip-money.svg')
              }
            ],
            resultUnit: this.$t('module1.part4.lesson2.gameBlock.unitEuro'),
            resultAnnotation: this.$t('module1.part4.lesson2.gameBlock.question2.resultAnnotation')
          },
          rightAnswer: 91000
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.image-wrapper {
  img {
    max-width: 100%;
  }
}
.block1-desktop {
  .image-wrapper {
    text-align: center;
    margin-top: $space-l;
    padding: 0 $space-s;
  }
}
.block1-desktop + .separator {
  margin-top: $space-m;
}
.app-text-block {
  margin-top: 0;
  padding: 0 $space-ml;
}
.app-block {
  margin: 0 $space-ml;
}
.calcul-cee-block {
  .separator {
    margin-top: $space-m;
    margin-left: -$space-l;
    margin-right: -$space-l;
  }
  .training-warning {
    margin-top: $space-sm;
  }
  .example {
    .label {
      text-transform: uppercase;
      font-size: $fz-xs;
      font-weight: $fw-l;
      color: $c-locked;
    }
    .operation {
      font-weight: $fw-l;
      font-size: 3vw;
      margin-top: $space-xs;
      word-break: break-word;
      span + span {
        margin-left: $space-s;
      }
      .primary {
        color: $c-primary;
      }
      .secondary {
        color: $c-secondary;
      }
    }
    .annotation {
      color: $c-locked;
      font-family: $ff-text;
      font-weight: $fw-m;
      font-size: $fz-s;
    }
  }
}
.training-question-block {
  margin-top: $space-ml;
  .quizz-activity {
    padding: 0;
    margin: 0;
    .cee-question {
      .tips {
        margin-top: $space-m;
        .tip + .tip {
          margin-top: $space-sm;
        }
        .tip {
          display: flex;
          align-items: center;
          .image-wrapper {
            width: 60px;
            display: flex;
            justify-content: center;
          }
          .tip-label {
            margin-left: $space-ml;
            color: $c-text-dark;
            font-size: $fz-s;
          }
        }
      }
      .modal-wrapper {
        padding: 0 $space-s;
        .app-button-layout {
          margin-top: $space-m;
          width: 100%;
        }
      }
    }
    .result-wrapper {
      display: flex;
      margin-top: $space-l;
      .result-label-wrapper {
        border-radius: $radius;
        background-color: rgba($c-error, .2);
        color: $c-error;
        font-size: $fz-s;
        height: 32px;
        padding: 0 $space-sm;
        font-weight: $fw-l;
        display: flex;
        align-items: center;
        justify-content: center;
        &.right {
          background-color: rgba($c-primary, .2);
          color: $c-primary;
        }
      }
      .result {
        margin-left: $space-l;
        .title {
          margin-bottom: $space-xs;
          font-size: $fz-s;
          font-weight: $fw-l;
          text-transform: uppercase;
        }
        .right-answer {
          color: $c-primary;
          font-size: $fz-ml;
          font-weight: $fw-l;
        }
        .result-annotation {
          color: $c-locked;
          font-family: $ff-text;
          font-weight: $fw-m;
          font-size: $fz-xs;
        }
      }
    }
  }
}
.button-wrapper {
  margin-top: $space-l;
  display: flex;
  justify-content: center;
  padding: 0 $space-s;
  .app-button-layout {
    width: 100%;
    max-width: 360px;
  }
}

@media (min-width: $bp-phone) {
  .calcul-cee-block .example .operation {
    font-size: $fz-s;
  }
}

@media (min-width: $bp-tablet) {
  .block1-desktop {
    display: flex;
    .image-wrapper {
      margin-top: 0;
      flex-shrink: 0;
    }
  }
  .block2-desktop {
    display: flex;
    .training-question-block {
      flex-shrink: 0;
      margin-top: 0;
      min-width: 410px;
    }
  }
}
</style>
